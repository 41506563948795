@tailwind base;
@tailwind components;
@tailwind utilities;

.slider-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: auto;
  overflow: hidden;
}

.slider-track {
  display: flex;
  white-space: nowrap;
  animation: scroll 25s linear infinite;
}

.slider-item {
  flex-shrink: 0;
  width: auto;
  display: inline-block;
  text-align: center;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-150%));
  }
}

a {
  color: #9dfff2 !important; 
  text-decoration: none !important;
  transition: color 0.3s ease; 
}

a:hover {
  color: #57c9b9 !important;
}